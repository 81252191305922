<template>
  <div>
    <div class="container-fluid" id="nav-container">
      <nav-bar></nav-bar>
    </div>
    <div class="container-fluid">
      <div class="row" id="contact">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 img">
          <img
            class="img-fluid"
            src="@/assets/Images/Contact/Grupo 1102.png"
            alt="contact-img"
          />
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 form">
          <div class="Card">
            <h3>{{ locale[lang].CONTACTO.TITLE }}</h3>
            <h5>{{ locale[lang].CONTACTO.SUBTITLE }}</h5>
            <form id="contact_form">
              <div class="input-group">
                <input
                  type="text"
                  name="user-name"
                  id="user-name"
                  v-model="name"
                  :placeholder="locale[lang].CONTACTO.PLACEHOLDERS.NAME"
                  class="form-control"
                />
              </div>
              <div class="input-group">
                <input
                  type="text"
                  name="user-email"
                  id="user-email"
                  v-model="email"
                  :placeholder="locale[lang].CONTACTO.PLACEHOLDERS.EMAIL"
                  class="form-control"
                />
              </div>
              <div class="input-group">
                <textarea
                  class="form-control"
                  id="user-message"
                  name="user-message"
                  rows="5"
                  :placeholder="locale[lang].CONTACTO.PLACEHOLDERS.MESSAGE"
                  v-model="message"
                ></textarea>
              </div>
              <div class="input-group">
                <input type="submit" :value="locale[lang].CONTACTO.BUTTON" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" id="footer-container">
      <Footer />
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/essential/NavBar.vue";
import Footer from "@/components/essential/Footer.vue";
export default {
  components: {
    NavBar,
    Footer,
  },
  mounted() {
    window.emailjs.init("user_od4hxjkv6iNt08Hd5vJPS");
    const form = document.getElementById("contact_form");
    const message = this.locale[this.lang].CONTACTO.ALERT
    form.addEventListener("submit", (event) => {
      event.preventDefault();
      if (
        !this.name ||
        this.name.trim() == "" ||
        !this.email ||
        this.email.trim() == "" ||
        !this.message ||
        this.message.trim() == ""
      ) {
        return alert(message);
      }
      window.emailjs
        .sendForm("service_7ser76b", "template_qiid335", form)
        .then(() =>
          this.$swal("Success", "Message sent successfully!", "success")
        )
        .catch(console.error)
        .finally(() => window.$("#contact-modal").modal("hide"));
    });
  },
  data() {
    return {
      name: "",
      email: "",
      message: "",
    };
  },
  created() {},
};
</script>

<style scoped>
#contact {
  background-image: url("../assets/Images/Contact/Grupo 1019.png");
  background-repeat: no-repeat;
  background-size: 340px 298px;
  background-position: 61% 5%;
  padding: 100px 100px;
}

.form {
  padding-left: 120px;
}

.Card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 15px #93939340;
  border-radius: 30px;
  opacity: 1;
  padding: 43px;
}

.Card h3 {
  text-align: center;
  font: normal normal bold 38px/50px Montserrat;
  letter-spacing: 0px;
  color: #0f1010;
  opacity: 1;
}

.Card h5 {
  text-align: center;
  font: normal normal 500 20px/32px Montserrat;
  letter-spacing: 0px;
  color: #0f1010;
  margin-bottom: 24px;
  opacity: 1;
}

.Card p {
  text-align: center;
  font: normal normal 500 16px/26px Montserrat;
  letter-spacing: 0px;
  color: #0f1010;
  opacity: 1;
}

.input-group input[type="text"],
.input-group textarea {
  border: 1px solid #707070;
  border-radius: 15px;
  opacity: 1;
  margin-bottom: 18px;
  font: normal normal 500 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #1e1e1e;
  opacity: 1;
}

.input-group input[type="text"] {
  height: 64px;
}

.input-group input[type="text"]::placeholder {
  font: normal normal 500 15px/19px Montserrat;
  letter-spacing: 0px;
  color: #1e1e1e;
  opacity: 1;
  padding-left: 16px;
}

.input-group textarea {
  height: 136px;
}

.input-group textarea::placeholder {
  font: normal normal 500 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #1e1e1e;
  opacity: 1;
  padding-top: 15px;
  padding-left: 16px;
}

.input-group input[type="submit"] {
  text-align: center;
  font: normal normal bold 22px/27px Montserrat;
  color: #0f1010;
  background-image: url("../assets/Images/Background/ball.png");
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-color: #ffffff;
  /* border: 1.7px solid #0f1010 !important; */
  box-shadow: 0px 3px 30px #00000029;
  border-radius: 15px;
  padding: 24px 0;
  width: 100%;
  margin-bottom: 45px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-size: 95px 80px;
}

.input-group input[type="submit"]:hover {
  background-color: rgba(209, 209, 209, 0.507);
  margin-left: 12px;
}

.img-fluid {
  object-fit: cover;
  margin-top: 70px;
}

.input-group {
  width: 100%;
}

@media (max-width: 1370px) {
  #contact {
    padding: 100px 70px;
  }
  .form {
    padding-left: 60px;
  }
}

@media (max-width: 1024px) {
  #contact {
    /* background-image: none; */
    background-size: 30%;
    background-position: 95% 5%;
    padding: 50px 0px;
    justify-content: center;
  }

  #contact .img {
    display: none;
  }

  .form {
    padding-left: 20px;
  }

  .Card h3 {
    font: normal normal 800 20px/24px Montserrat;
    margin-bottom: 32px;
  }

  .Card h5 {
    font: normal normal 500 16px/20px Montserrat;
  }

  .Card p {
    text-align: center;
    font: normal normal 500 16px/18px Montserrat;
    color: #505050;
  }

  .input-group input[type="text"]::placeholder,
  .input-group textarea::placeholder {
    font: normal normal 500 14px/18px Montserrat;
    color: #9e9e9e;
  }

  .input-group input[type="text"],
  .input-group textarea {
    font: normal normal 500 14px/18px Montserrat;
    border: 1px solid #dcdcdc;
  }

  .input-group input[type="submit"] {
    text-align: center;
    font: normal normal bold 14px/18px Montserrat;
    padding: 16px 0;
    margin-bottom: 48px;
  }
}

@media (max-width: 375px) {
  .form {
    padding-left: 15px;
  }
}
</style>
